<template>
    <header class="header block">
        <div class="block-content-wrapper">
            <div class="header-logo">
                <div class="logo-container">
                    <router-link class="div-1" to="/">
                        <span class="font-header name">{{ content.title }}</span>
                        <span class="font-header logo">{{ content.logo }}</span>
                    </router-link>
                </div>
            </div>
            <div class="logo-menu">
                <div class="menu-links" id="wide-window-menu">
                    <router-link class="font-header nav-link" to="/">HOME</router-link>
                    <router-link class="font-header nav-link" to="/technical-blog">TECHNICAL BLOG</router-link>
                    <a class="font-header nav-link"  @click="scrollToAnchor('#contacts')">CONTACT US</a>
                </div>
                <Slide right closeOnNavigation id="burger-menu">
                    <router-link class="font-header nav-link" to="/">HOME</router-link>
                    <router-link class="font-header nav-link" to="/technical-blog">TECHNICAL BLOG</router-link>
                    <a class="font-header nav-link"  @click="scrollToAnchor('#contacts')">CONTACT US</a>
                </Slide>
            </div>
        </div>
    </header>
    <router-view />
    <div id="contacts" class="block">
        <div class="block-content-wrapper">
            <div class="block-title">
                    <span class="title-icon"></span>
                    <h2>{{ contacts.title }}</h2>
            </div>
            <div class="block-content">
                <form @submit.prevent="sendForm">
                    <div class="form-group">
                        <label for="email">Email:</label>
                        <input type="text" v-model="user.email" id="email" name="email">
                        <span class="error-text" v-show="errors.email">{{errors.email}}</span>
                    </div>
                    <div class="form-group">
                        <label for="fname">First name:</label>
                        <input type="text" v-model="user.firstName" id="fname" name="fname">
                        <span class="error-text" v-if="errors.firstName">{{errors.firstName}}</span>
                    </div>
                    <div class="form-group">
                        <label for="lname">Last name:</label>
                        <input type="text" v-model="user.lastName" id="lname" name="lname">
                        <span class="error-text" v-if="errors.lastName">{{errors.lastName}}</span>
                    </div>
                    <div class="form-group">
                        <input id="contact-us-button" type="submit" :click="sendForm" value="Submit">
                    </div>
                </form>
                <div class="image">
                    <img :src="sources.contact_us[0]"/>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <div class="block-content-wrapper">
            <div class="copy">
                <span>&copy; 2020 Echo Diagnostic AI</span>
            </div>
            <div class="address">
                <object :data="sources.contact_us[1]" type="image/svg+xml">
                </object>
                <div>
                    <span>21781 Ontur, Mission Viejo, CA 92692</span>
                    <span>United States of America</span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import contentJson from "@/data/content.json";
import axios from 'axios';
import Slide from './components/Slide';

export default {
  name: 'Main',
  components: { Slide },
  data() {
      return {
        open: false,
        content: contentJson.product,
        contacts: contentJson.home.contacts,
        user: {
            email: "",
            firstName: "",
            lastName: "",
        },
        valid: true,
        success: false,
        errors: {},
        message: null,
        sources: {
            "contact_us": ["contact_us.jpg", "location.svg"]
        },
        burgerMenu: null,
      }
  },
  mounted() {
    const images = require.context(`@/assets/images`, false);
    for (let key in this.sources) {
        this.sources[key] = this.sources[key]
                                .map(name => images(`./${name}`));
    }
  },
  methods: {
        scrollToAnchor(refName) {
            setTimeout(() => { location.href = refName }, 1);
        },
        validateEmail(email) {
            if (!email.length) {
                return { valid: false, error: "This field is required" };
            }
            if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                return { valid: false, error: "Please, enter a valid email." };
            }
            return { valid: true, error: null };
        },
        validateName(name) {
            if ( !name.replace(" ", "").length ) {
                return { valid: false, error: "This field is required" };
            }
            return { valid: true, error: null };
        },
        sendForm() {
            this.errors = {};
            const validFirstName = this.validateName(this.user.firstName);
            this.errors.firstName = validFirstName.error;
            if (this.valid) {
                this.valid = validFirstName.valid;
            }
            const validLastName = this.validateName(this.user.lastName);
            this.errors.lastName = validLastName.error;
            if (this.valid) {
                this.valid = validLastName.valid
            }
            const validEmail = this.validateEmail(this.user.email);
            this.errors.email = validEmail.error;
            if (this.valid) {
                this.valid = validEmail.valid
            }
            if (this.valid) {
                console.log('HURRAAYYY!! :-)\n\n' + JSON.stringify(this.user));
                axios.post('https://api.echodxai.com/request', this.user)
                    .then(response => console.log(response));

                this.user = {
                    email: "",
                    firstName: "",
                    lastName: "",
                };
            }
        },
        onResize() {
            if (window.innerWidth > 660) {
                this.burgerMenu.style.display = 'none';
            }
            else {
                this.burgerMenu.style.display = 'block';
            }
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Biryani&display=swap');
html {
    scroll-behavior: smooth;
    min-width: 280px;
}
#app {
    font-family: Biryani, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    max-width: 100vw;
    scroll-behavior: smooth;
}
body {
    margin: 0;
    width: 100%;
    color: $black;
    scroll-behavior: smooth;
}
.font-header {
    color: $dark-white;
}
.block {
    display: flex;
    justify-content: center;
    width: 100vw;
    box-sizing: border-box;
    padding: 8rem 3rem;
    .block-content-wrapper {
        max-width: 1200px;
        width: 100%;
    }
}
.header {
    height: 80px;
    width: 100%;
    padding: 0 3rem;
    box-sizing: border-box;
    background-image: $gradient;
    min-width: 280px;
    position: relative;
    z-index: 100;
    .block-content-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-logo {
        height: 100%;
        .logo-container {
            display: flex;
            height: 100%;
            cursor: pointer;
            .div-1 {
                text-decoration: none;
            }
            .logo-img {
                display: flex;
                align-items: center;
            }
            img {
                max-width: 60px;
                max-width: 60px;
                height: 55px;
                vertical-align: middle;
            }
            .div-1 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .name{
                    font-size: 26px;
                    width: 90px;
                    height: 40%;
                    letter-spacing: 4px;
                    font-weight: 600;
                    color: $light-main;
                }
                .logo {
                    font-size: 14px;
                }
            }
        }
    }
}

.logo-menu {
    .menu-links{
        height: 100%;
        align-items: center;
        display: flex;
        vertical-align: middle;
        justify-content: space-between;

        .nav-link {
            vertical-align: middle;
            word-wrap: break-word;
            letter-spacing: 2px;
            text-decoration: none;
            margin-right: 50px;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
         .nav-link:last-child {
            margin-right: 0;
        }
        .nav-link::after {
            content: "";
            display: block;
            width: 25px;
            height: 1px;
            text-align: center;
            background: #F5F5F5;
            opacity: 0;
            transition-duration: 0.3s;
        }
        .nav-link:hover::after{
            opacity: 1;
            transition-duration: 0.2s;
        }
    }
}
footer {
    .block-content-wrapper {
        max-width: 1200px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    min-width: 280px;
    background-color: #303030;
    width: 100%;
    display: flex;
    justify-content: center;
    color: $dark-white;
    padding: 2rem 3rem;
    box-sizing: border-box;
    font-size: 16px;
    .address {
        display: flex;
        align-items: flex-start;
        div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        object {
            width: 20px;
            height: 20px;
            margin-right: 7px;
        }
        span {
            display: block;
        }
    }
}
 #contacts {
    background-image: $gradient;
    padding: 6rem 3rem;
    h2 {
        font-size: 1.5rem;
        margin: 0;
        font-weight: 100;
    }
    .block-content {
        width: 100%;
        display: flex;
        box-sizing: border-box;
    }
    .content {
        position: relative;
    }
    .block-content {
        padding-left: 50px;
        color: $dark-white;
        text-align: justify;
        display: flex;
        .image {
            display: flex;
            width: calc(40% - 50px);
            justify-content: center;
            margin-left: 50px;
            img {
                object-fit: cover;
                width: 100%;
            }
        }
        .text {
            width: 60%;
        }
    }
    .block-title {
        text-align: start;
        font-size: 1.5em;
        color: $light-main;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 3rem;
        width: 60%;
        display: flex;
        span {
            display: inline-block;
        }
    }
    .title-icon::before {
        width: 50px;
        display: block;
        content: "\1405";
    }
    form {
        width: 60%;
    }
    label {
        display: block;
        margin-bottom: 10px;
        color: $dark-white;
        letter-spacing: 1px;
    }
    .form-group {
        margin-bottom: 30px;
    }
    input {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid $light-main;
        border-radius: 10px;
        height: 40px;
        outline: none;
        padding: 20px;
        box-sizing: border-box;
        color: $dark-white;
        font-size: 16px;
        letter-spacing: 1px;
    }
    input[type="submit"] {
        background: $light-main;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        padding: 0;
        width: 200px;
        font-size: 16px;
        color: $white;
        margin-top: 40px;
        text-transform: uppercase;
        letter-spacing: 2px;
        height: 50px;
         -webkit-appearance: none;
    }
    .image {
        width: 40%;
        img {
            border-radius: 10px;
            height: 80%;
        }
    }
}
.error-text {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    color: salmon;
    letter-spacing: 1px;
}
@media (max-width: 900px) {
     #contacts {
        padding: 6rem 3rem;
        .block-content { 
            .image {
                display: none;
            }
            form {
                 width: 100%;
            }
        }
     }
}
@media (max-width: 767px) {
    #wide-window-menu {
        display: none;
    }
    #burger-menu {
        display: block;
    }
    #contacts  {
        h2 {
            font-size: 1.2rem;
        }
        label {
            font-size: 14px;
        }
        .block-title {
            width: 100%;
            align-items: center;
        }
    }
}
@media (min-width: 767px) {
    #wide-window-menu {
        display: flex;
    }
    #burger-menu {
        display: none;
    }
}
@media (max-width: 660px) {
    .header {
        padding: 0 1.5rem;
    }
    #contacts  {
        padding: 3rem 1.5rem;
        .block-content {
            padding: 0;
        }
        .title-icon::before {
            display: none;
        }
    }
    footer {
        padding: 2rem 1.5rem;
        font-size: 12px;
        .block-content-wrapper {
            flex-direction: column;
        }
        .address {
            order: 1;
        }
        .copy {
            order: 2;
            border-top: 1px solid grey;
            width: 100%;
            padding-top: 1rem;
            margin-top: 1rem;
        }
    }
}
</style>
