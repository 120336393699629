<template>
    <div class="page-content">
        <Video>
            <h1 class="block-title">
              <span class="title-highlighted">{{ content.main_highlighted }}</span>
              <span>{{ content.main }}</span>
            </h1>
            <h4 class="block-foot">{{ content.foot }}</h4>
        </Video>
        <HomeContent :block1="block1" 
              :block2="block2" 
              :block3="block3" 
              :block4="block4"
              :block5="block5"/>
    </div>
</template>

<script>
import Video from './Video.vue'
import HomeContent from './HomeContent.vue'
import contentJson from "@/data/content.json";

export default {
  name: 'HomePage',
  components: { Video, HomeContent },
  data() {
    return {
      content: contentJson.videoOverlay,
      block1: contentJson.home.block1,
      block2: contentJson.home.block2,
      block3: contentJson.home.block3,
      block4: contentJson.home.block4,
      block5: contentJson.home.block5,
    }
  }
}
</script>

<style scoped lang="scss">
.page-content {
    scroll-behavior: smooth;
    width: 100%;
    min-width: 280px;
}
.block-title {
    text-shadow: none;
    color: $dark-white;
    font-weight: 100;
    text-align: left;
    letter-spacing: 2px;
    line-height: 50px;
    span {
      display: block;
    }
    .title-highlighted {
      text-transform: uppercase;
      color: $light-main;
      font-size: 1.7em;
      letter-spacing: 4px;
      text-shadow: 1px 0px black;
      line-height: 60px;
    }
}
.block-foot {
    color: $dark-white;
}
@media (max-width: 767px) {
    .block-title {
      span {
        font-size: 16px;
        line-height: 40px;
      }
      .title-highlighted {
        font-size: 1.2em;
        line-height: 50px;
      }
    }
}
</style>