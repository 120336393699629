import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/technical-blog',
    name: 'BlogListPage',
    // lazy load
    component: () => import('@/components/BlogListPage.vue')
  },
  {
    path: '/technical-blog/:slug',
    name: 'BlogPage',
    component: () => import('@/components/BlogPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
