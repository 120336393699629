<template>
    <div class="container">
        <div class="gradient-background">
        </div>
        <div class="page-content">
        <div class="block-1 block">
            <div class="block-content-wrapper">
                <div class="block-title">
                    <span class="title-icon"></span>
                    <h2>{{ block1.title }}</h2>
                </div>
                <div class="block-content">
                    <div class="text">
                        <p>{{ block1.text }}</p>
                    </div>
                    <div class="image">
                        <img :src="sources.block1[0]"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-2 block">
            <div class="dark-bg"></div>
            <div class="block-content-wrapper">
                <div class="block-2-header">
                    <div class="block-title">
                        <span class="title-icon"></span>
                        <h2>{{ block2.title }}</h2>
                    </div>
                    <div class="block-content">
                        <div class="text">
                            <p>{{ block2.text }}</p>
                        </div>
                    </div>
                </div>
                <div class="model-cards-container">
                    <h3>{{ content.block2.models_description.title }}</h3>
                    <ul class="cards">
                        <li v-for="(item, index) in content.block2.models_description.list" :key="item.title">
                            <div class="image">
                                <img :src="sources.block_models_desciprion[index]"/>
                            </div>
                            <div class="text">
                                <span>{{ item.title }}</span>
                                <p> {{ item.description }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="block-3 block">
            <div class="block-content-wrapper">
                <div class="block-3-header">
                    <div class="block-title">
                        <span class="title-icon"></span>
                        <h2>{{ block3.title }}</h2>
                    </div>
                    <div class="block-content">
                        <div class="text">
                            <p>{{ block3.text }}</p>
                        </div>
                    </div>
                </div>
                <div class="animation-block">
                    <video autoplay playsinline muted="muted" loop="true" ref="video" :src="videos.block3[0]"></video>
                </div>
            </div>
        </div>
        <div class="block-4 block">
            <div class="block-content-wrapper">
                <div class="animation-block">
                    <video autoplay playsinline muted="muted" loop="true" ref="video" :src="videos.block4[0]"></video>
                </div>
                <div class="block-4-header">
                    <div class="block-title">
                        <span class="title-icon"></span>
                        <h2>{{ block4.title }}</h2>
                    </div>
                    <div class="block-content">
                        <div class="text">
                            <p>{{ block4.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block-5 block">
            <div class="block-content-wrapper">
                <div class="block-title">
                        <span class="title-icon"></span>
                        <h2>{{ block5.title }}</h2>
                    </div>
                    <div class="block-content">
                        <div class="numbers-block">
                            <p class="title">{{block5.blocks[1].title}}</p>
                            <object :data="sources.by_numbers[1]" type="image/svg+xml">
                            </object>
                            <p class="value">{{block5.blocks[1].value}}</p>
                        </div>
                        <div class="numbers-block">
                            <p class="title">{{block5.blocks[0].title}}</p>
                            <object :data="sources.by_numbers[0]" type="image/svg+xml">
                            </object>
                            <p class="value">{{block5.blocks[0].value}}</p>
                        </div>
                        <div class="numbers-block">
                            <p class="title">{{block5.blocks[2].title}}</p>
                            <object :data="sources.by_numbers[2]" type="image/svg+xml">
                            </object>
                            <p class="value">{{block5.blocks[2].value}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import contentJson from "@/data/content.json";

export default {
    name: 'Main',
    props: {
        block1: Object,
        block2: Object,
        block3: Object,
        block4: Object,
        block5: Object
    },
    data() {
        return {
            content: contentJson.home,
            sources: {
                "block1": ["echocardiography.jpg"],
                "block2": ["ejection_fraction.jpg"],
                "block_models_desciprion": ["types.jpg", "ejection_fraction.jpg", "calcification.jpg", "leaflets_motion.jpg", "ai_creates_ai.jpg", "measurements.jpg"],
                "by_numbers": ["ai.svg", "cloud-computing.svg", "accuracy.svg"],
                "contact_us": ["contact_us.jpg"]
            },
            videos: {
                "block3": ["priority.mp4"],
                "block4": ["workflow.mp4"]
            }
        }
    },
    mounted () {
        const images = require.context(`@/assets/images`, false);
        const videos = require.context(`@/assets/video`, false);
        for (let key in this.sources) {
            this.sources[key] = this.sources[key]
                                    .map(name => images(`./${name}`));
        }
        for (let key in this.videos) {
            this.videos[key] = this.videos[key]
                                    .map(name => videos(`./${name}`));
        }
    },
    methods: {
        scrollToAnchor(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo(0, top);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss'>
h2 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 100;
}
h3 {
    margin: 0;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.container {
    min-width: 280px;
    scroll-behavior: smooth;
    width: 100vw;
    display: flex;
    justify-content: center;
    position: relative;
    background-position: center;
    background-image: url("../assets/images/background1.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    .page-content {
        .content {
            position: relative;
        }
        .block {
            display: flex;
            justify-content: center;
            width: 100vw;
            box-sizing: border-box;
            padding: 8rem 3rem;
            .block-content-wrapper {
                max-width: 1200px;
                width: 100%;
            }
        }
        .block-title {
            text-align: start;
            font-size: 1.5em;
            color: $light-main;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 3rem;
            width: 70%;
            display: flex;
            span {
                display: inline-block;
            }
        }
        .title-icon::before {
            width: 50px;
            display: block;
            content: "\1405";
        }
        .block-content {
            padding-left: 50px;
            color: $dark-white;
            text-align: justify;
            display: flex;
            .image {
                display: flex;
                width: calc(40% - 50px);
                justify-content: center;
                margin-left: 50px;
                align-items: center;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 300px;
                    border-radius: 10px;
                }
            }
            .text {
                width: 70%;
            }
        }
        .block-1 {
            background-image: $gradient;
        }
        .block-2 {
            position: relative;
            padding-bottom: 6rem;
            .dark-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.7);
            }
            .block-2-header {
                padding-bottom: 3rem;
                position: relative;
                z-index: 1;
            }
            .block-content {
                padding-left: 50px;
                position: relative;
                z-index: 1;
            }
            .model-cards-container {
                width: 100%;
                background-color: rgba(255, 255, 255, 0.95);
                border-radius: 20px;
                padding: 6rem;
                box-sizing: border-box;
                position: relative;
                z-index: 1;
                h3 {
                    margin-bottom: 40px;
                    text-align: left;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 1.2rem;
                    color: #303030;
                }
                .cards {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        flex: 1 1 calc(50% - 3rem);
                        display: flex;
                        margin-bottom: 3rem;
                        .image {
                            width: 150px;
                            height: 150px;
                            margin-right: 3rem;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                                object-fit: cover;
                                object-position: top;
                            }
                        }
                        .text {
                            width: calc(100% - 150px);
                            text-align: left;
                            span {
                                font-size: 16px;
                                font-weight: 600;
                                color: #303030;
                                text-align: left;
                            }
                            span::after {
                                content: "";
                                display: block;
                                width: 40px;
                                margin: 10px 0;
                                height: 1px;
                                background-color:#505050;
                            }
                            p {
                                margin-top: 20px;
                                font-size: 12px;
                                text-align: justify;
                            }
                        }
                    }
                    li:nth-child(odd) {
                        margin-right: 3rem;
                    }
                    
                }
            }
        }
        .block-3, .block-4 {
            display: flex;
            .block-content-wrapper {
                display: flex;
            }
            .block-3-header, .block-4-header {
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .block-title {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            .block-content {
                text-align: left;
                .text {
                    width: 100%;
                }
            }
        }
        .block-3 {
            background: #303030;
            .animation-block {
                width: 60%;
                display: flex;
                align-items: center;
                padding-left: 3rem;
                align-items: center;
                box-sizing: border-box;
                video {
                    width: 100%;
                    border-radius: 10px;
                }
            }
        }
        .block-4 {
            background-image: $gradient;
            .animation-block {
                width: 60%;
                margin-right: 3rem;
                display: flex;
                align-items: center;
                padding-left: 3rem;
                align-items: center;
                box-sizing: border-box;
                video {
                    width: 100%;
                    border-radius: 10px;
                }
            }
        }
        .block-5 {
            background-color: rgba(0, 0, 0, 0.7);
            .block-content {
                display: flex;
                .numbers-block {
                    width: calc(100% / 3);
                    margin-right: 3rem;
                    display: flex;
                    flex-direction: column;
                    p {
                        margin: 0;
                    }
                    .title {
                        font-size: 22px;
                        text-align: center;
                    }
                    .value {
                        font-size: 2rem;
                        text-align: center;
                        color: $white;
                    }
                }
                .numbers-block:last-child {
                    margin-right: 0;
                }
                .text {
                    width: 80%;
                }
            }
        }
    }
}
object {
    width: 100%;
    height: 150px;
    margin: 20px 0;
}
object ::v-deep svg {
    fill: white;
    color: white;
}
@media (max-width: 1200px) {
    .container .page-content .block-2 .model-cards-container {
        padding: 3rem;
    }
}
@media (max-width: 900px) {
    .container {
        .page-content {
            .block {
                padding: 6rem 3rem;
            }
            .block-title {
                width: 100%;
            }
            .block-content {
                padding-right: 50px;
                padding-left: 50px;
                .image {
                    display: none;
                }
                .text {
                    width: 100%;
                }
            }
            .block-2 {
                .model-cards-container .cards {
                    li  {
                        flex: 1 1 100%;
                        margin-right: 0;
                    }
                    li:nth-child(odd) {
                        margin-right: 0;
                    }
                    li:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .block-3, .block-4 {
                .block-content-wrapper {
                    flex-direction: column;
                }
                .block-3-header, .block-4-header {
                    width: 100%;
                }
                .text {
                    margin-bottom: 20px;
                }
                .animation-block {
                    width: 100%;
                    padding-right: 50px;
                }
            }
            .block-4 {
                .animation-block {
                    order: 2;
                }
            }
            .block-5 .block-content {
                .numbers-block {
                    .title {
                        font-size: 18px;
                        text-align: center;
                    }
                    .value {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
    object {
        height: 100px;
    }
}
@media (max-width: 764px) {
    .container {
        .page-content {
            h2 {
                font-size: 1.2rem;
            }
            p {
                font-size: 14px;
            }
            .block-title {
                align-items: center;
            }
        }
    }
    .container .page-content .block-5 .block-content {
        flex-direction: column;
        align-items: center;
        .numbers-block {
            width: 100%;
            .title {
                font-size: 16px;
                text-align: center;
            }
            .value {
                font-size: 1.5rem;
            }
        }
        .numbers-block:not(:last-child) {
            margin: 0;
            margin-bottom: 3rem;
            margin-top: 1.5rem;
        }
    }
    object {
        height: 70px;
    }
}
@media (max-width: 660px) {
    .container {
        .page-content {
            h2 {
                font-size: 1.2rem;
            }
            p {
                font-size: 14px;
            }
            .block {
                padding: 3rem 1.5rem;
            }
            .block-title {
                align-items: center;
                margin-bottom: 1.5rem;
            }
            .block-content {
                padding: 0;
            }
            .title-icon::before {
                display: none;
            }
            .block-2 {
                .block-2-header {
                    padding-bottom: 1.5rem;
                }
                .block-content {
                    padding-left: 0;
                }
                .model-cards-container {
                    padding: 1.5rem;
                }
            }
            .block-3, .block-4 {
                .animation-block {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }  
}
@media (max-width: 550px) {
    .container .page-content .block-2 .model-cards-container .cards li {
        flex-direction: column;
        .image {
            margin: 0;
            width: 100%;
            height: 200px;
        }
        .text {
            width: 100%;
            margin-top: 1.5rem;
        }
    }
    .container .page-content .block-5 .block-content .numbers-block .title {
        font-size: 18px;
    }
}
</style>
