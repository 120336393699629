<template>
  <section class="content">
    <div class="video-background">
        <div id="header-image" ref="image">
            <img :src="images[0]" />
        </div>
        <video id="header-video" autoplay playsinline :muted="muted" ref="video" loop="true" :src="videosrc" :type="getMediaType(videosrc)"></video>
    </div>
    <div class="VideoBg__content block">
        <div class="block-content-wrapper">
            <slot></slot>
        </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
        imgsrc: {
            type: String
        },
        muted: {
            type: Boolean,
            default: true
        }
    },
    data() {
      return {
        videoRatio: null,
        sourceNames: [ 'header.mp4'],
        images: ['first-frame-header.jpg'],
        sources: [],
        currentIndx: 0
      }
    },
    mounted () {
        const videos = require.context(`@/assets/video`, false)
        this.sources = this.sourceNames.map(name => videos(`./${name}`))

        const images = require.context(`@/assets/images`, false)
        this.images = this.images.map(name => images(`./${name}`));

        if (this.videoCanPlay()) {
            this.$refs.video.oncanplay = () => {
                if (!this.$refs.video) return
                this.videoRatio = this.$refs.video.videoWidth / this.$refs.video.videoHeight
                this.$refs.video.style.visibility = 'visible'
                this.$refs.video.currentIndex = this.currentIndx
                this.$refs.image.style.visibility = 'hidden'
            }
            this.$refs.video.onended = () => {
                this.changeVideo(this.$refs.video.currentIndex)
            }
        }
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.resize)
    },
    computed: {
        videosrc() {
            return this.sources[this.currentIndx]
        }
    },
    methods: {
        getVideoUrl(videosrc) {
            const videos = require.context(`@/assets/video`, false)
            return videos(`./${videosrc}`)
        },
        changeVideo(crntIndx) {
            this.currentIndx = (++crntIndx) % this.sources.length;
        },
        videoCanPlay () {
            return !!this.$refs.video.canPlayType
        },
        getMediaType (src) {
            return src ? 'video/' + src.split('.').pop() : 'video/mp4'
        }
    }
  }
</script>


<style scoped lang="scss">
.block {
    display: flex;
    justify-content: center;
    width: 100vw;
    box-sizing: border-box;
    padding: 8rem 3rem;
    .block-content-wrapper {
        max-width: 1200px;
        width: 100%;
    }
}
.content {
    height: calc(100vh - 80px);
    position: relative;
    min-height: 350px;
    .video-background {
        height: 100%;
        #header-image {
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 0px;
            left: 0;
            object-fit: cover;
            visibility: visible;
            background: black;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        video {
            position: fixed;
            visibility: hidden;
            width: 100%;
            height: 100vh;
            z-index: -1;
            top: 0px;
            left: 0;
            overflow: hidden;
            object-fit: cover;
        }
    }
    .VideoBg__content {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        text-shadow: 1px 1px 2px #162229, 0 0 1em #162229;
        border-radius: 50px;
        display: flex;
        align-items: center;
        padding: 0 calc(3rem + 50px);
        box-sizing: border-box;
        z-index: 3;
    }
}
.content::after {
  content: "";
  background: rgb(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
@media (max-width: 900px) {
    .content .VideoBg__content {
        padding: 0 calc(3rem + 50px);
    }
}
@media (max-width: 660px) {
    .content .VideoBg__content {
        padding: 3rem 1.5rem;
    }
}
</style>
